export let baseURL = "";
export let uploadURL = "";
export let CAPTCHA_SECRET_KEY = "";
export let CRYPTO_SECRET_KEY = ""



if (window.location.href.includes('dev.msvcnagpur.in') || window.location.href.includes('localhost') || window.location.href.includes('ebadev')) {
    baseURL = process.env.REACT_APP_MSVC_BASE_URL
    uploadURL = process.env.REACT_APP_MSVC_UPLOAD_URL
    CAPTCHA_SECRET_KEY = process.env.REACT_APP_CAPTCHA_SECRET_KEY
    CRYPTO_SECRET_KEY = process.env.REACT_APP_CRYPTO_SECRET_KEY

}
else if (window.location.href.includes('test.msvcnagpur.in')) {
    baseURL = process.env.REACT_APP_MSVC_BASE_URL_TESTPROD
    uploadURL = process.env.REACT_APP_MSVC_UPLOAD_URL_TESTPROD
    CAPTCHA_SECRET_KEY = process.env.REACT_APP_CAPTCHA_SECRET_KEY_TESTPROD
    CRYPTO_SECRET_KEY = process.env.REACT_APP_CRYPTO_SECRET_KEY_TESTPROD

} else {
    baseURL = process.env.REACT_APP_MSVC_BASE_URL_PROD
    uploadURL = process.env.REACT_APP_MSVC_UPLOAD_URL_PROD
    CAPTCHA_SECRET_KEY = process.env.REACT_APP_CAPTCHA_SECRET_KEY_PROD
    CRYPTO_SECRET_KEY = process.env.REACT_APP_CRYPTO_SECRET_KEY_PROD
}

// 
// 
var CryptoJS = require("crypto-js");

export const encrypt = value => {
  const encrypted = CryptoJS.AES.encrypt(value, CRYPTO_SECRET_KEY);
  return encrypted.toString();
};
// 
// 


export const emailRegex = /^[a-z.0-9]{1,}@[a-z]{3,}[.]{1}[a-z.]{2,}$/;

// export const postalCodeRegex = /^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/;

export const postalCodeRegex = /^[0-9]{6}$/;

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#()^@$!%*?&])[A-Za-z\d@$!#()^%*?&]{8,30}$/;

export const phoneRegex = /^[0-9]{10}$/;

export const adharRegex = /^[0-9]{12}$/;

export const feedbackRegex = /([^ ]+( |$)){11,}/;

export const aadharRegex = /^[0-9]{12}$/;

export const nameRegex = /^[A-Za-z ]+$/;

export const userNameRegex = /^[ A-Za-z0-9_@./#&+-]*$/;

export const allRegex = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;

export const htmlRegex = /^[a-zA-Z0-9_,.:\s]+$/;

export const otpRegex = /[a-zA-z][0-9].[a-zA-z]/;

export const fieldRegex = /^[A-Za-z, ]+$/;

export const getMonthName = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December',
}

export const getMonthNumber = {
    'Jan': '01',
    'Fab': '02',
    'Mar': '03',
    'Apr': '04',
    'May': '05',
    'Jun': '06',
    'Jul': '07',
    'Aug': '08',
    'Sept': '09',
    'Oct': '10',
    'Nov': '11',
    'Dec': '12',

}
export const getProperDateMonth = (value) => {
    if (value < 10 && value.length !== 2) {
        return `0${value}`
    } else {
        return value
    }
}

export const downloadPDF = (link, name) =>{
    fetch(`${uploadURL}${link}`).then(response => {
        response.blob().then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = name;
            alink.click();
        })
    })
}

