import React, { useState, useEffect, useRef } from 'react'
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { baseURL, emailRegex, nameRegex, htmlRegex, feedbackRegex, phoneRegex,CAPTCHA_SECRET_KEY } from "../../constants/applicationConstants";
import './feedback.css';
import { ToastContainer, toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import ClipLoader from "react-spinners/ClipLoader";
export const FeedbackForm = () => {
    let [loading, setLoading] = useState(false);
    // let [color, setColor] = useState("#ffffff");
    let [isProcessing, setProcessing] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        address: '',
        feedback: ''
    });

    const [formError, setFormError] = useState({})
    const [formSubmitButtonDisable, setFormSubmitButtonDisable] = useState(true)

    const [recapchaVerified, setRecaptchaVerified] = useState(false);

    const recaptchaRef = useRef();

    useEffect(() => {
        isFromSubmissionBlock()
    })

    const onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value) {
            if (!inputRegexValidation(name, value)) {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "regx" })
            } else {
                setFormData({ ...formData, [name]: value })
                setFormError({ ...formError, [name]: "" })
            }
        } else {
            setFormData({ ...formData, [name]: '' })
            setFormError({...formError, [name]: ""})
        }
    };

    const inputRegexValidation = (name, value) => {
        if (name === "name")
            return nameRegex.test(value)
        else if (name === 'address')
            return htmlRegex.test(value)
        else if (name === 'feedback')
            return htmlRegex.test(value)
        else if (name === 'mobile')
            return phoneRegex.test(value);
        else if (name === 'email')
            return emailRegex.test(value);
        else
            return true;
    };

    const isFromSubmissionBlock = () => {
        let { name, mobile, email, address, feedback } = formData;
        if (name && mobile && email && address && feedback && recapchaVerified) {
            if (
                inputRegexValidation("name", name) &&
                inputRegexValidation("mobile", mobile) &&
                inputRegexValidation("email", email)
            ) {
                setFormSubmitButtonDisable(false)
            } else {
                setFormSubmitButtonDisable(true)
            }
        } else {
            setFormSubmitButtonDisable(true)
        }
    };

    const onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            setFormError({ ...formError, [name]: "req" })
        } else {
            if (!inputRegexValidation(name, value)) {
                setFormError({ ...formError, [name]: "regx" })
            }
        }
    }

    const onResetButtonClick = () => {
        setFormData({
            name: '',
            mobile: '',
            email: '',
            address: '',
            feedback: ''
        })
        setFormError('');
        setRecaptchaVerified(false);
        recaptchaRef.current.reset();
    }

    const onSubmitButtonClick = () => {
        setLoading(true)
        setProcessing(true);
        const payloadData = {
            name: formData.name,
            mobile: formData.mobile,
            email: formData.email,
            address: formData.address,
            feedback: formData.feedback
        }
        axios.post(`${baseURL}sendFeedback`, payloadData).then((res) => {
            recaptchaRef.current.reset();
            setLoading(false)
            if (!!res.status) {
                toast.success("Feedback added successfully!")
                setProcessing(false);
                onResetButtonClick()
            } else {
                toast.error("Something wrong!")
                setProcessing(false);
            }
        }).catch((err)=>{
            toast.error(err?.response?.data.message)
            recaptchaRef.current.reset();
            setProcessing(false);
        })
    }


    const onChange = (value) => {
        if (value) {
            setRecaptchaVerified(true);
        }
    }

    const { t } = useTranslation();

    return (
        <>
            <div className="container officeStaffComponentOuter">
                <div className="bread_crums mt-3"><Link to="/"> {t("FEEDBACK.HOME")}</Link>/ <Link to="/feedback"> {t("FEEDBACK.FEEDBACK")}</Link></div><span className="print" onClick={() => window.print()}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="skyBlue" class="bi bi-printer-fill" viewBox="0 0 16 16">
                    <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                    <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                </svg> &nbsp; {t("PRINT.TITLE")}</span>
                <h2 className="loginHeading mt-2">
                    {t("FEEDBACK.TITLE")}
                </h2>
                <div className="input" >
                    <InputGroup className="col-md-12"  >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("FEEDBACK.NAME")}<span className="star_highlight">*</span>
                            </InputGroup.Text>
                        <FormControl
                            id="basic-url"
                            name='name'
                            onBlur={onTouchHandler}
                            aria-describedby="basic-addon3"
                            value={formData.name}
                            onChange={onInputChange}
                            placeholder={t("FEEDBACK.NAME_PLACEHOLDER")}
                            maxLength={60}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['name'] &&
                            (formError['name'] === "req"
                                ? 'Name is required'
                                : 'Enter a valid Name')}
                    </span>
                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("FEEDBACK.MOBILE")}<span className="star_highlight">*</span>
                            </InputGroup.Text>
                        <FormControl
                            id="basic-url"
                            name='mobile'
                            onBlur={onTouchHandler}
                            aria-describedby="basic-addon3"
                            value={formData.mobile}
                            onChange={onInputChange}
                            placeholder={t("FEEDBACK.MOBILE_PLACEHOLDER")}
                            maxLength={10}
                        />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['mobile'] &&
                            (formError['mobile'] === "req"
                                ? 'Mobile Number is required'
                                : 'Enter a valid Mobile Number')}
                    </span>
                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
                            {t("FEEDBACK.EMAIL")}<span className="star_highlight">*</span>
                            </InputGroup.Text>
                        <FormControl id="basic-url"
                            name='email'
                            onBlur={onTouchHandler}
                            aria-describedby="basic-addon3"
                            value={formData.email}
                            onChange={onInputChange}
                            placeholder={t("FEEDBACK.EMAIL_PLACEHOLDER")}
                            maxLength={60}
                            />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['email'] &&
                            (formError['email'] === "req"
                                ? 'Email Id is required'
                                : 'Enter a valid Email Id')}
                    </span>
                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 feedback mb-3">
                            {t("FEEDBACK.ADDRESS")}<span className="star_highlight">*</span>
                            </InputGroup.Text>
                            <Form.Control
                                className="form-control"
                                as="textarea"
                                placeholder= {t("FEEDBACK.ADDRESS_PLACEHOLDER")}
                                id="exampleFormControlTextarea1"
                                rows={3}
                                onBlur={onTouchHandler}
                                name='address'
                                style={{ marginBottom: '20px',resize:'none' }}
                                value={formData.address} 
                                onChange={onInputChange}
                                maxLength={500}
                            />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['address'] &&
                            (formError['address'] === "req"
                                ? 'Address is required'
                                : 'Enter a valid Address')}
                    </span>
                    <InputGroup className="col-md-12" >
                        <InputGroup.Text id="basic-addon3" className="col-md-3 feedback mb-3">
                            {t("FEEDBACK.FEEDBACK")}<span className="star_highlight">*</span>
                            </InputGroup.Text>
                         <Form.Control
                                className="form-control"
                                as="textarea"
                                placeholder= {t("FEEDBACK.FEEDBACK_PLACEHOLDER")}
                                id="exampleFormControlTextarea1"
                                rows={3}
                                onBlur={onTouchHandler}
                                name="feedback"
                                style={{ marginBottom: '20px',resize:'none' }}
                                value={formData.feedback}
                                onChange={onInputChange}
                                maxLength={500}
                            />
                    </InputGroup>
                    <span className="text-danger">
                        {
                            formError['feedback'] &&
                            (formError['feedback'] === "req"
                                ? 'Feedback is required'
                                : 'Enter a valid Feedback')}
                    </span>

                    <div className="recaptcha_css">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={CAPTCHA_SECRET_KEY}
                            onChange={onChange}
                        />
                    </div>
                    <div class="row">
                        <div class="col-sm-12 text-center mb-5 mt-3">
                            <button id="btnSearch" class="btn center-block mr-4" onClick={onResetButtonClick} >{t("REGISTER.RESET")}</button>
                            <button disabled={formSubmitButtonDisable || !!isProcessing} id="btnClear" class="btn center-block" onClick={onSubmitButtonClick}>{!!isProcessing ? t("REGISTER.PROCESSING") : t("REGISTER.SUBMIT")}</button>
                        </div>
                    </div>

                </div>
            </div>
            {(loading) &&
                <div className="loaderPosition">
                    {/* <ClipLoader color={'green'} size={100} /> */}
                </div>
            }
            <ToastContainer />
        </>
    )
}