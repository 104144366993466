import React, { useState } from "react";
import "./faq.css";
import greaterThanIcon from "../../assets/images/greaterThanIcon.svg";
import faq from "./faq.json";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const FAQJsx = () => {
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="container faqComponentOuter">
      <div className="bread_crums mt-3">
        <Link to="/">{t("FAQ.HOME")}</Link> / <Link to="/faq">{t("FAQ.FAQ")}</Link>
      </div>
      <span className="print" onClick={() => window.print()}>
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="skyBlue" className="bi bi-printer-fill" viewBox="0 0 16 16">
          <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
          <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
        </svg>{" "}
        &nbsp; {t("PRINT.TITLE")}
      </span>
      <h2 className="loginHeading mt-2">{t(`${faq.heading}`)}</h2>

      {faq.ARR.map((item, index) => (
        <div key={index} className="faqComponent">
          <div className="faqHeadDiv">
            <h5 className="faqQuestion" onClick={() => toggleAnswer(index)}>
              {t("FAQ.QUE")} {t(`${item.question}`)}
            </h5>
            <img src={greaterThanIcon} className={openIndex === index ? "faqIconRotate" : "faqIcon"} alt="" />
          </div>
          {openIndex === index && (
            <div className="faqAnswer">
              <p>
                {t("FAQ.ANS")} {t(`${item.answer}`)}
              </p>
            </div>
          )}
        </div>
      ))}
      <br />
      <br />
    </div>
  );
};

export default FAQJsx;
