import React, { useState, useEffect } from 'react'
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import axios from 'axios';
import { baseURL, nameRegex, passwordRegex } from "../../constants/applicationConstants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

export const ValiateJsx = () => {
  const history = useHistory();

  let [isProcessing, setProcessing] = useState(false);
  // let [loading, setLoading] = useState(false);
  // const [albumListData, setAlbumData] = useState([]);

  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    Otp: '',
  });
  const [formError, setFormError] = useState({})
  const [formSubmitButtonDisable, setFormSubmitButtonDisable] = useState(true)
  useEffect(() => {
    isFromSubmissionBlock();
  })
  const onInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value) {
      if (!inputRegexValidation(name, value)) {
        setFormData({ ...formData, [name]: value })
        setFormError({ ...formError, [name]: "regx" })
      } else {
        setFormData({ ...formData, [name]: value })
        setFormError({ ...formError, [name]: "" })
      }
    } else {
      setFormData({ ...formData, [name]: '' })
      setFormError({})
    }
  };
  const inputRegexValidation = (name, value) => {
    if (name === "Otp")
      return nameRegex.test(value)
    else
      return true;
  };
  const isFromSubmissionBlock = () => {
    let { Otp } = formData;
    if (Otp) {
      if (
        !inputRegexValidation("Otp", Otp)
      ) {
        setFormSubmitButtonDisable(true)
      } else {
        setFormSubmitButtonDisable(false)
      }
    } else {
      setFormSubmitButtonDisable(true)
    }
  };
  const onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      setFormError({ ...formError, [name]: "req" })
    }
  }
  const onSubmitButtonClick = () => {
    // setLoading(true)
    setProcessing(true);
    const data = {
      token: JSON.parse(sessionStorage.getItem('authenticateToken')),
      otp: formData.Otp,
    }
    axios.post(`${baseURL}admin/otpValidation`, data).then((res) => {
      // setLoading(false)
      if (res.data.status === "success") {
        toast.success(res.data.message)
        setProcessing(false);
        sessionStorage.setItem("authToken", JSON.stringify(res.data?.data?.access_token));
        sessionStorage.setItem("masterID", JSON.stringify(res.data?.data?.master_doctor_id));
        sessionStorage.setItem("role", JSON.stringify(res.data?.data?.role));
        setTimeout(() => {
          history.push("/adminDashboard");
          window.location.reload()
        }, 3000)


      } else {
        toast.error(res.data.message)
        setProcessing(false);
      }
    }).catch((res) => {
      setProcessing(false);
      toast.error(res.response?.data.message)
    })
  }
  console.log("id", window.location.search.split('=')[2])
  // console.log("token",window.location.search.split('=')[1].replace('&id',''))



  return (
    <>
      <div className="container officeStaffComponentOuter">
        <div className="bread_crums mt-3"><Link to="/">{t("UPLOAD_MAIN_IMAGE.HOME")}</Link>/ <Link to="/grievance">                   Validate OTP
</Link></div><span className="print" onClick={() => window.print()}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="skyBlue" class="bi bi-printer-fill" viewBox="0 0 16 16">
          <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
          <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
        </svg> &nbsp; {t("PRINT.TITLE")}</span>
        <h2 className="loginHeading mt-2">
          Validate OTP
                </h2>
        <hr />
        <div className="input" >


          <InputGroup className="col-md-12"  >
            <InputGroup.Text id="basic-addon3" className="col-md-3 mb-3">
              OTP<span className="star_highlight">*</span>
            </InputGroup.Text>
            <FormControl id="basic-url"
              name='Otp'
              onBlur={onTouchHandler}
              value={formData.Password}
              onChange={onInputChange}
              aria-describedby="basic-addon3"
              placeholder="Enter the OTP" />
          </InputGroup>
          <span className="text-danger">
            {
              formError['Otp'] &&
              (formError['Otp'] === "req"
                ? 'Otp is required'
                : 'Enter a valid Otp')}
          </span>

          <div class="row">
            <div class="col-sm-12 text-center mb-5 mt-3">
              <button disabled={formSubmitButtonDisable || !!isProcessing} id="btnClear" class="btn center-block" onClick={onSubmitButtonClick}>{!!isProcessing ? t("REGISTER.PROCESSING") : t("REGISTER.SUBMIT")}</button>
            </div>
          </div>



        </div>
      </div>

      <ToastContainer />
    </>
  )
}
export default ValiateJsx;