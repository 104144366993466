import ValidateJsx from "./validate.jsx";

function Validate(){

    return (
        
     <ValidateJsx/>
    );
  }

export default Validate;